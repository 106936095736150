import en from '~/lang/en.json'
import de from '~/lang/de.json'
import it from '~/lang/it.json'
import keys from '~/lang/keys.json'
import { defineI18nConfig } from '#i18n'

const addMessages = (): { [index: string]: Record<string, string> } => {
  if (process.env.NUXT_PUBLIC_VERCEL_ENV !== 'production') {
    return {
      en,
      de,
      it,
      keys
    }
  }
  return {
    en,
    de,
    it
  }
}
export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: addMessages(),
  warnHtmlMessage: false
}))
